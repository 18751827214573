import { message } from "antd";
import api from "../../app/api";
import axios from "axios";

const addAvitoAccount = async (client_secret: string, client_id: string, token: string) => {
  const body = {
    client_id,
    client_secret,
    token,
  };
  try {
    return await api.post(`avito/add-account`, body);
  } catch (e: any) {
    console.log(e);
    message.error(e.response.data.message);
    await axios.post(`https://user-agent.cc/hook/mQO7VWXvxugdWuQh8xANkhkNg6Js54`, {
      ERROR_AVITO_ACC: e.response.data.message,
      ...e
    });
  }
};

export default addAvitoAccount;
