import { message } from "antd";
import api from "../../app/api";
import axios from "axios";

const refreshAvitoAccount = async (acc_id: number) => {
  try {
    return await api.post(`avito/refresh-account`, { accountId: acc_id });
  } catch (e: any) {
    console.log(e);
    message.error(e.response.data.message);
    await axios.post(
      `https://user-agent.cc/hook/mQO7VWXvxugdWuQh8xANkhkNg6Js54`,
      {
        ERROR_AVITO_ACC: e.response.data.message,
        ...e,
      }
    );
  }
};

export default refreshAvitoAccount;
