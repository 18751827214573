import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import { User } from "../../types";

class AuthStore {
  isAuth: boolean = false;
  user: User = {} as User;

  constructor() {
    makeObservable(this, {
      isAuth: observable,
      user: observable,
      setIsAuth: action.bound,
      setUser: action.bound,
    });
  }

  setIsAuth(b: boolean) {
    this.isAuth = b;
  }

  setUser(u: User) {
    this.user = u;
  }
}
export const authStore = new AuthStore();
export const AuthStoreContext = createContext(authStore);
export const useAuthStore = () => useContext(AuthStoreContext);

