import { message } from "antd";
import api from "../../app/api";
import { avitoStore } from "../../app/stores";

const getAvitoAccounts = async ({ token }: { token: string }) => {
  try {
    const accounts = await api.get(`avito/accounts`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    avitoStore.setAccounts(accounts.data);
    avitoStore.setAccountsLoaded(true);
  } catch (e) {
    message.error("Ошибка получения аккаунтов");
  }
  return;
};

export default getAvitoAccounts;
