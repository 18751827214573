import api from "../../app/api";
import { LogsResponse } from "../../types";

const getLogs = async ({
  token,
  page,
  pageSize,
}: {
  token: string;
  page: number;
  pageSize: number;
}) => {
  const logs = await api.get(
    `logs/get?token=${token}&page=${page}&pageSize=${pageSize}`
  );
  return logs.data as LogsResponse;
};

export default getLogs;
