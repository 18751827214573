import { Navigate, RouteObject } from "react-router-dom";
import { AvitoAccounts, Logs } from "../../pages";

const prefix = `/app`;

const router: RouteObject[] = [
  { path: "/", element: <Navigate to={`/app`} /> },
  { path: `/${prefix}`, element: <AvitoAccounts title="Аккаунты авито" /> },
  { path: `/${prefix}/avito`, element: <AvitoAccounts title="Аккаунты авито" /> },
  { path: `/${prefix}/logs`, element: <Logs title="Логи"/> },
];

export default router;
