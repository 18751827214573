import { Input, Modal, message } from "antd";
import { observer } from "mobx-react";
import { useState } from "react";
import { addAvitoAccount } from "../shared";
import { useAuthStore, useAvitoStore } from "../app/stores";

const addAccountModal = observer(() => {
  const [client_id, setClientId] = useState<string>("");
  const [client_secret, setClientSecret] = useState<string>("");

  const { setAccounts, accounts, opennedPopup, setOpennedPopup } =
    useAvitoStore();

  const { user } = useAuthStore();

  const sendRequest = async () => {
    const req = await addAvitoAccount(client_secret, client_id, user.token);
    if(req!.status > 300) return;

    if(req?.status === 409)return message.error("Аккаунт уже добавлен ранее")
    if(req?.status === 404)return message.error("Произошла ошибка! Перезайдите с помощью токена")
    if(req?.status === 400)return message.error("Аккаунт не валиден")
    if(req?.status === 403)return message.error("Пользователь не валиден")
    
    if(req?.status !== 201)return message.error(req?.statusText)

    const account = req?.data?.candidate
    const isChange = req?.data?.isChange

    if(!account || isChange === undefined)return message.error("Произошла ошибка! Перезайдите с помощью токена")

    if(!isChange) {
      message.success("Аккаунт добавлен");
      setAccounts(accounts.concat(account));
    }
    else {
      message.success("Ранее добавленный аккаунт был изменен")
      setAccounts(account.map((acc: any) => {
        if(acc.id === account.id) {
          acc.name = account.name
          acc.phone = account.phone
          acc.email = account.email
          acc.active = account.active
          acc.profile_url = account.profile_url
        }
        return acc
      }))
    }

    setOpennedPopup(false);
  };

  return (
    <Modal
      title="Добавление аккаунта"
      open={opennedPopup}
      onCancel={() => setOpennedPopup(false)}
      onOk={sendRequest}
    >
      <p>client_id</p>
      <Input
        placeholder="client_id"
        onChange={({ target }) => setClientId(target.value)}
        aria-description="client_id"
      />
      <p>client_secret</p>
      <Input
        placeholder="client_secret"
        onChange={({ target }) => setClientSecret(target.value)}
      />
    </Modal>
  );
});

export default addAccountModal;
