import { Button, Input, message } from "antd";
import { observer } from "mobx-react";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { checkToken } from "../shared";
import { useAuthStore } from "../app/stores";

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;

  div {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  h1 {
    font-size: 1.6em;
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: 500;
  }

  p {
    font-size: 0.9em;
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 1.48;
  }
`;

const EnterToken: FC<{ title: string }> = observer(({ title }) => {
  const [token, setTokenInput] = useState<string>("");
  const { setUser, setIsAuth } = useAuthStore();
  const navigate = useNavigate();

  const setToken = async () => {
    if (!token) {
      return message.error("Укажите токен");
    }
    try {
      const response = await checkToken(token);

      if (response && typeof response !== "boolean") {
        console.log(typeof response, response);
        setUser(response);
        setIsAuth(true);
        navigate(`?token=${token}`);
      } else {
        message.error("Невалидный токен");
      }
    } catch (error) {
      message.error("Какая-то ошибка при проверке токена (см. консоль)");
      console.error(error);
    }
  };

  return (
    <>
      <title>{title}</title>
      <Layout>
        <div>
          <h1>Укажите Ваш токен</h1>
          <p>
            Токен можете уточнить у Вашего партнера или на странице виджета в
            amoCRM
          </p>
          <Input
            placeholder="Ваш токен"
            value={token}
            onChange={({ target }) => setTokenInput(target.value)}
          />
          <Button type="primary" onClick={setToken}>
            Применить
          </Button>
        </div>
      </Layout>
    </>
  );
});

export default EnterToken;
