import { action, makeObservable, observable } from "mobx";
import { Logs } from "../../types";
import { createContext, useContext } from "react";

class LogsStore {
  logs: Logs[] = [];
  logsLoaded: boolean = false;

  constructor() {
    makeObservable(this, {
      logs: observable,
      logsLoaded: observable,
      setLogs: action.bound,
      setLogsLoaded: action.bound,
    });
  }

  setLogs(logs: Logs[]) {
    this.logs = logs;
  }

  setLogsLoaded(b: boolean) {
    this.logsLoaded = b;
  }
}

export const logsStore = new LogsStore();
export const LogsStoreContext = createContext(logsStore);
export const useLogsStore = () => useContext(LogsStoreContext);
